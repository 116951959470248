import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {UserAccountManagementService} from '../../user-account-management/user-account-management.service';
import {UserRole} from '../../models/user-role.enum';

@Directive({
	selector: '[agaShowIfUserAuthorized]'
})

export class ShowIfUserAuthorizedDirective implements OnInit {
	@Input('agaShowIfUserAuthorized') authorizedUserRoles: Array<UserRole>;

	constructor(private elementRef: ElementRef, private userAccountManagementService: UserAccountManagementService) {
	}

	ngOnInit(): void {
		if (!this.userAccountManagementService.isUserAuthorized(this.authorizedUserRoles)) {
			this.removeElement();
		}
	}

	removeElement(): void {
		this.elementRef.nativeElement.remove();
	}
}
